.form-ui {
	padding: 0 1rem 0 0;

	.form-bg {
		//background: url(../images/modalFormBg.png) no-repeat;
		padding: 5rem 2rem;
		background-position: left center;
		// background-size: 100%;
		background-size: 125%;
	}
}

// .fake-border{
// 	height: 10px;
// 	background-color: red;
// 	border-bottom: 5px solid green
// }