.chatModal {
  background: #eef2f5;
  font-size: 14px;
  width: 360px;
  position: fixed;
  right: 35px;
  bottom: 0;
  z-index: 1200;
  box-shadow: -1px 0 20px #55555569;

  .chat-header {
    padding: 1rem;
    margin-bottom: 0.4rem;
    // background-image: linear-gradient(315deg, #673bb7, #2495f2 74%);
    background-image: linear-gradient(
      315deg,
      #643db9,
      #4764d3 57%,
      #3181e5 86%
    );
    // background: #3f51b5;
    @extend .text-white;
    @extend .pointer;

    .tbl-fxd {
      // table-layout: fixed;
      width: 100%;
      td {
        font-size: 0.65rem;
        text-transform: capitalize !important;
        //   border: 1px solid #ddd;
      }
      .icon-td {
        width: 20px;
      }
    }
  }

  .chat-body {
    // min-height: 28rem;
    min-height: 20rem;
    max-height: 300px;
    @media screen and (max-width: 578px) {
        max-height: inherit;
    }
    overflow-y: auto;

    .chat-threads {
      padding: 0.6rem 1rem;
      margin-bottom: 1rem;
      width: 19rem;
    }

    .chat-thread-initiated {
      text-align: left;
      @extend .chat-threads;
      background-color:#d6e6eb;
      float: left;
      margin-left: 12px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      color: #4e5152;
      // box-shadow: 5px 6px 13px #ddd;
      border: 1px solid transparent;
      // margin-left: calc(460px - 120rem)
    }

    .chat-thread-response {
      // text-align: right;
      @extend .chat-threads;
      margin-bottom: 10px;
      margin-right: 12px;
      color: #4e5152;
    //   @extend .text-white;
      float: right;
      background-color: #FFF;
    //   background-color: #2795f3;
    //   background-image: linear-gradient(
    //     315deg,
    //     #643db9,
    //     #4764d3 57%,
    //     #3181e5 86%
    //   );
      // background: #3f51b5;
      // box-shadow: 5px 6px 13px #b4b7bfad;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .chat-footer {
    margin: 0;
    padding: 0.5rem;
    background-color: #eeeeee;

    .input-action {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 80% auto auto;
      align-items: center;
      position: relative;
      .chat-input {
        height: 45px;
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      background: #3f51b5;
      border: 0;
      height: 100%;
    }

    textarea {
      height: 60px;
      font-size: 14px;
      border: 0;
      outline-style: solid;
      outline-color: #cfdbff;
    }
  }
  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0 !important;

    //
  }
  .MuiExpansionPanelSummary-root.Mui-expanded {
    min-height: auto;
  }
//   .MuiIconButton-label:not(form.messageForm .MuiIconButton-label) {
//     color: #FFF
//   }
}

// .chatModal {
//   .MuiIconButton-sizeSmall {
//       @extend .isAbsolute;
//       top: 6px !important;
//       right: 4px !important;
//       // background-color: #ff0000bd;
//       @extend .bg-white;
//       // &:hover {
//       //     background-color: red;
//       // }
//   }
// }

@media screen and (max-width: 578px) {
  .chatModal {
    width: 100vw;
    height: 100vh;
    right: 0;
    // z-index: 1301 !important;
    overflow-y: auto;
  }
  .chat-footer {
    .input-action {
      grid-template-columns: 75% auto auto !important;
    }
  }
  // .chatModal .MuiIconButton-sizeSmall {
  //   top: 6px !important;
  //   right: 4px !important;
  // }
}
