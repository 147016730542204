@keyframes blinkAnimation {
    from, to {
    //   opacity: 0;
      background-color: #2959ba;
    }
    50% {
    //   opacity: 1;
      background-color: #16acff;
    }
  }
  @keyframes blink {
    from, to {
    //   opacity: 0;
      background-color: #2959ba;
    }
    50% {
    //   opacity: 1;
      background-color: #00838f;
    }
  }

@keyframes shine {
    0% {
        transform:translateX(-100%) translateY(-100%) rotate(30deg);
    }
    80% {transform:translateX(-100%) translateY(-100%) rotate(30deg);}
      100% {transform:translateX(100%) translateY(100%) rotate(30deg);}
}