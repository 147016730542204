.bg-black-gradient {
	background-color: #000000;
}

._404 {
	@extend .text-center;

	h1 {
		font-size: 12rem;
	}

	.error-description {
		// max-width: 30rem;
		// color: #fff;
		font-size: 1.5rem;
	}
}

// @include media-breakpoint-down(xs) {
	
// }