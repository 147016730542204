// @import '~bootstrap/scss/bootstrap';
// @import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Roboto+Mono:400,500|Material+Icons');
@import "homepage-hero.scss";
// @import '../src/scss/homepage-intro.scss';
// @import '../src/scss/newsletter.scss';
// @import '../src/scss/blog-post.scss';
// @import '../src/scss/contact.scss';
// @import "footer.scss";
// @import '../src/scss/sellerBuyersSection.scss';
// @import '../src/scss/lisitng-filter.scss';
// @import '../src/scss/indexes.scss';
@import "chat-ui.scss";
@import "form-validations.scss";
@import "../sass/errors/404.scss";
@import "./keyframes.scss";

@keyframes blinker {
    50% {
        opacity: 0
    }
}
$buyer-primary-color: #00838F;
$seller-primary-color: #2959BA;
// @import '../src/scss/listing-table.scss';
// $enable-gradients: true;

// .trace * {
// 	outline-color: red;
// 	outline-style: dashed;
// 	outline-width: 1px;
// }

// .heading-title {
// 	top: 25%;
// 	left: 75px;
// }

// .heading-color-dark {
// 	color: #000;
// }

// .ff-heading {
// 	font-family: 'Poppins', sans-serif;
// 	font-weight: 700;
// 	@extend .heading-color-dark
// }

// .color-theme-red {
// 	color: #ff0000;
// }

// .bg-gradient {
// 	background: linear-gradient(to left, #673AB7 0%, #2196F3)
// }

// .border-bottom {
// 	border-bottom: 1px solid #ececec;
// 	overflow: hidden;
// 	padding: 40px 30px 30px;
// }

// .bg-theme-red {
// 	background-color: #ff0000;
// }

// .tablePadding td {
// 	padding: 0.25rem;
// 	vertical-align: top;
// 	border-top: 0;
// }
body {
    // background-color: #0675ce03 !important;
    // background-color: #F2F3F5 !important;
}
.dashed-border-top {
    padding-top: 10px;
    border-top: 2px grey dashed;
}
.dashed-border-bottom {
    //padding-top: 10px;
    border-bottom: 2px grey dashed;
}

.gray {
    color: gray;
}

.console-root {

}

.textMuted {
    color: "#999999"
}

.console-main {
    padding-bottom: 100px !important
}

// .bg-gray-second {
// 	background-color: #eaeff2;
// }

// .bg-green {
// 	background-color: #65a619;
// }

// .ml-6 {
// 	margin-left: 4.1rem !important;
// }

// .border-buyer {
// 	margin-right: 10px;
// 	border: 2px solid lightgrey;
// 	padding: 5px;
// 	margin-left: 10px;
// }

// .buyer-seller-margin {
// 	margin-right: 10px;
// 	padding: 5px;
// 	margin-left: 10px;
// }

// .border-left {
// 	padding: 10px;
// 	border-left: 2px solid lightgrey;
// }

// a {
// 	@extend .text-decoration-none
// }

// .global-nav {
// 	@extend .position-absolute;
// 	@extend .w-100;
// 	z-index: 1;
// 	// background: transparent none repeat scroll 0 0;
// 	@extend .bg-gradient;
// 	top: 0;
// 	padding: .2rem 0;
// 	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
// 	box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.04);

// 	.navbar-brand {
// 		@extend .position-absolute;
// 		margin: 0;
// 		// padding: 4rem;
// 		top: -3px;
// 		left: -20px;
// 		background-repeat: no-repeat;

// 		// height: 9.687rem;
// 		img {
// 			@extend .position-absolute;
// 			top: 8px;
// 			left: 18px;
// 		}
// 	}

// 	.navbar-nav {
// 		li>.nav-link {
// 			@extend .text-white;
// 		}
// 	}

// 	.nav-sign-up {
// 		border: 1px solid #ddd;
// 	}
// }
.border-1 {
    border: 1px solid #ddd;
}
.bgGrad,
.page-header {
    background: linear-gradient(to left, #673ab7 0%, #2196f3);
}

// @keyframes fadeIn {
// 	from {
// 		opacity: 0
// 	}

// 	to {
// 		opacity: 1
// 	}
// }

.scrollingItem {
    @extend .isAbsolute;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap;
    animation: scroll 25s linear infinite;
    &:hover {
        animation-play-state:paused;
    }
}

@keyframes scroll {
    0% {
        transform: translate(-1%, 0);
    }
    100% {
        transform: translate(-30%, 0);
    }
}

// .text-gradient {
// 	-webkit-background-clip: text;
// 	-webkit-text-fill-color: transparent;
// 	background-color: #653cb7;
// 	background-image: linear-gradient(315deg, #673bb7, #2495f2 74%);
// }

// .bg-primary {
// 	background-color: #2893f2
// }

// .toast-notification {
// 	position: fixed;
// 	top: 30px;
// 	left: 0;
// 	right: 0;
// 	margin: auto;
// 	min-width: 300px;
// 	max-width: 600px;

// 	.toast {
// 		background: red;
// 		margin: auto
// 	}
// }

// .horizontal-card {
// 	transition: all .3s;
// 	box-shadow:
// 		0 1px 1px rgba(0, 0, 0, 0.15),
// 		0 10px 0 -5px #eee,
// 		0 10px 1px -4px rgba(0, 0, 0, 0.15);

// 	// @extend .mr-3
// 	a {
// 		color: #333;
// 		@extend .font-weight-bold;
// 	}

// 	&:hover {
// 		box-shadow:
// 			0 1px 1px rgba(0, 0, 0, 0.15),
// 			0 10px 0 -5px #eee,
// 			0 10px 1px -4px rgba(0, 0, 0, 0.15),
// 			0 20px 0 -10px #eee,
// 			0 20px 1px -9px rgba(0, 0, 0, 0.15);

// 		a {
// 			color: #222;
// 		}

// 		// &:after {
// 		// 	width: 100%;
// 		// 	visibility: visible;
// 		// }
// 	}
// }

// .article-sidebar {
// 	li {
// 		@extend .mb-2;

// 		a {
// 			color: #333;
// 		}
// 	}
// }

// .sharing-widget {
// 	@extend .text-center;

// 	>div {
// 		@extend .d-inline-block;
// 		@extend .my-2;
// 	}
// }

// .pricing-red {
// 	border-top: 3px #ff6a6d solid;
// }

// .pricing-blue {
// 	border-top: 3px #0674ec solid;
// }

// .pricing-yellow {
// 	border-top: 3px #feb71d solid;
// }

// .plan-pricing-list {
// 	li {
// 		// font-size: 1.5rem;
// 		@extend .position-relative;

// 		&:before {
// 			content: '';
// 			@extend .d-block;
// 			width: 10px;
// 			height: 10px;
// 			background-color: #c5cad4;
// 			@extend .position-absolute;
// 			top: 8px;
// 			left: -20px;
// 			border-radius: 50%;
// 		}
// 	}
// }

.or {
	width: 100%;
	@extend .text-center;
	border-bottom: 1px solid #e0e0e0;
	line-height: 0.1em;
	margin: 2rem 0 20px;

	span {
		@extend .bg-white;
		padding: 0 10px;
	}
}

// .sticky {
// 	position: fixed;
// 	top: 0;
// 	width: 100%;
// 	z-index: 99;
// 	font-size: 15px;
// 	transition: 0.3s all;
// }

// label:not(.custom-control-label) {
// 	@extend .font-weight-bold;
// 	color: #495057;
// }

// .stepProgress {
// 	display: grid;
// 	grid-template-columns: repeat(3, 1fr);
// 	grid-gap: 1rem;
// 	align-items: center;
// 	@extend .position-relative;

// 	&:after {
// 		content: "";
// 		@extend .bg-gradient;
// 		line-height: 1.2em;
// 		border-radius: 1.2em;
// 		width: 100%;
// 		height: 15px;
// 		left: 0;
// 		top: 22px;
// 		@extend .position-absolute;
// 		z-index: -91;
// 	}

// 	.step {
// 		// width: 100%;
// 		@extend .text-center;
// 		@extend .bg-gray-second;
// 		@extend .mb-3;
// 		@extend .p-3;
// 		box-sizing: border-box;
// 		border-radius: 4rem;
// 	}

// 	.steps-ui {
// 		width: 25px;
// 		height: 25px;
// 		line-height: 24px;
// 		border-radius: 50%;
// 		@extend .mr-3;
// 		@extend .bg-gradient;
// 		@extend .text-white;
// 		@extend .d-inline-block;
// 	}

// 	.active-step {
// 		@extend .position-relative;

// 		&:before {
// 			content: "\25CF";
// 			@extend .steps-ui;
// 			@extend .bg-gradient;
// 		}
// 	}

// 	.step-done {
// 		&:before {
// 			content: "\2713";
// 			@extend .steps-ui;
// 			background: linear-gradient(to left, #4CAF50 0%, #009688);
// 			@extend .font-weight-bold;
// 		}
// 	}
// }

// .img-faq {
// 	max-width: 800px;
// 	@extend .py-sm-5;
// 	@extend .mx-auto;
// }

// .shape-1 {
// 	margin-left: -150px;
// }

// .shape-2 {
// 	margin-right: -120px;
// }

// Global classes
.debug {
    border: 1px dashed red;
}
.is-absolute {
    position: absolute !important;
}

.is-relative {
    position: relative;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.text-danger {
    color: red !important
}
.text-white {
    color: #fff !important;
}
.bg-red {
    background-color: #f3c5c5 !important;
}
.bg-white {
    background-color: #fff !important;
}
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.isRelative {
    position: relative !important;
}
.isAbsolute {
    position: absolute !important;
}
.r-0 {
    right: 0 !important;
}
.tag {
    text-align: center;
    font-size: .7rem !important;
    border-radius: 4px;
    margin-top: 0.25rem !important;
    font-weight: bold;
    padding: 0 .4rem;
    &.warning {
        color: #252323;
        background: #FFC107;
    }
    &.success {
        @extend .text-white;
        background: #4caf50;
    }
    &.danger {
        @extend .text-white;
        background: #f44336;
    }
}
.closeBtn {
    // top: -8px;
    // right: -8px;
    top: 8px;
    right: 8px;
    @extend .isAbsolute;
    z-index: 1 !important;
}

.formSpacing {
    padding: 32px;
    margin-top: 32px;
    margin-bottom: 32px;
}
.d-block {display: block !important;}

.pointer {
    cursor: pointer;
}

html {
    --scrollbarBG: #cfd8dc;
    // --thumbBG: #283593;
}
body::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: $seller-primary-color var(--scrollbarBG) ;
}

body.buyerTheme {
    scrollbar-color: $buyer-primary-color var(--scrollbarBG) ;
}

body.sellerTheme {
    scrollbar-color: $seller-primary-color var(--scrollbarBG) ;
}

body::-webkit-scrollbar-track {
    // background: var(--scrollbarBG);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    border-radius: 2px;
    border: 1px solid var(--scrollbarBG);
    background-color: $seller-primary-color;
}

body.buyerTheme::-webkit-scrollbar-thumb {
    background-color: $buyer-primary-color;
}

body.sellerTheme::-webkit-scrollbar-thumb {
    background-color: $seller-primary-color;
}

.scroll-to-top {
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
    animation: fadeIn 700ms ease-in-out 1s both;
    @extend .pointer;
    z-index: 1;
}
.pretendNav {
    max-width: 124px;
}
.fix-on-scroll {
    position: -webkit-sticky;
    position: sticky !important;
    top: 0;
    z-index: 1;
    // &.pretendNav {
    //     background-color: #ddd;
    //     max-width: 100%;
    // }
}

.text-right {
    text-align: right !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.uppercase {
    text-transform: uppercase !important;
}
.lowercase {
    text-transform: lowercase !important;
}
.customAlign {
    input {
        @extend .text-right;
    }
}
.text-center {
    text-align: center !important;
}

.Divider {
    border: none;
    height: 2px;
    margin: 0;
    flex-shrink: 0;
    background-color: #3f51b5;
}

.reviewAddCompanyForm {
    table {
        @extend .border-1;
        td:first-child {
            width: 200px;
        }
        tr:nth-child(even) {
            background: rgba(63, 81, 181, 0.05);
        }
    }
}

.fsSmall,
.sendQuotationForm {
    .fsSmall {
        .MuiTypography-body1 {
            font-size: 0.75rem !important;
        }
    }
    .MuiContainer-root {
        padding-left: 2px;
        padding-right: 2px;
    }
}

a {
    text-decoration: none;
}

// .makeStyles-initialStarColor-925 .MuiRating-iconEmpty {
//     color: #94a2ed;
// }

.acceptAgreement {
    @extend .isRelative;
    span {
        @extend .d-block;
        margin-left: 25px;
    }
}

.partitionedInput {
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(
        to left,
        black 70%,
        rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 52px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 300px;
    font-size: 1.2rem;
    outline: 0;
    margin-bottom: 1rem;
}
.opt-validate {
    form {
        width: 100px;
        margin: auto;
    }
}
// .chatModal {
//     .MuiIconButton-sizeSmall {
//         @extend .isAbsolute;
//         // top: -8px;
//         right: -8px;
//         background-color: #ff0000bd;
//         &:hover {
//             background-color: red;
//         }
//     }
// }

.circularBoxContainer {
    margin-top: -18rem;
}

.productSearchContainer {
    button {
        // height: 100%;
        height: 54px;
        @extend .d-block;
    }
    .MuiFilledInput-root,
    .MuiFilledInput-root:hover,
    .MuiFilledInput-root.Mui-focused {
        background-color: #fff !important;
    }
    .searchButtonGroup {
        height: 100%;
        width: 100%;
        button {
            border-radius: 0px;
            .MuiButton-label {
                font-size: 12px !important;
            }
        }
    }

    .MuiButtonGroup-contained,
    .MuiButton-contained:hover {
        box-shadow: none;
    }

    select,
    .MuiOutlinedInput-root,
    .MuiFilledInput-root {
        border-radius: 0 !important;
    }
}

.form-label__checkbox {
    margin-right: 1rem !important;
}
.otp-validate {
    .MuiButton-textPrimary {
        margin-top: 0;
    }
}

.noSpacing,
.starRatings {
    fieldset {
        margin: 0;
        padding: 0;
    }
}

.border-light {
    border: 6px double #ddd;
}

.border-single-light {
    border: 2px solid #757575;
}

.border-price {
    padding: 3px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
}

.border-bottom {
    padding: 3px;
    border-bottom: 2px dotted grey;
}

.centerForm {
    max-width: 265px;
    margin: auto;
}

// Custom loader

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #0000001f;
    z-index: 9999;
    .loader {
        img {
            position: absolute;
            top: 40%;
            // width: 40px;
            // height: 40px;
            // background-color: blue($color: #000000);
            width: 60px;
            height: 60px;
            margin: auto;
            left: 0;
            right: 0;
            animation: loading 3s infinite linear;
            transform-origin: inherit;
            background-color: #fff;
            padding: 8px;
            border-radius: 50%;
        }
    }
}

.custom-table-head {
    .MuiTableRow-head {
        background-color: #eee;
    }
}
.mt1 {
    margin-top: 1rem;
}

.userModeToggleGroup {
    margin-top: 15px;
    .MuiToggleButton-root.Mui-selected,
    .MuiToggleButton-root.Mui-selected:hover {
        background: #3f51b5;
        @extend .text-white;
    }
}

.theadBg {
    background-color: #3f51b5;
    .MuiTableCell-head {
        @extend .text-white;
    }
}

.trust {
    .trust-grid {
        text-align: center;
        > .block {
            display: inline-block;
            vertical-align: top;
            position: relative;
            // width: 220px;
            width: 19%;
            min-width: 220px;
            .letter {
                background: linear-gradient(
                    90deg,
                    rgba(63, 81, 181, 0.11) 20%,
                    #fff
                );
                width: 160px;
                height: 160px;
                border-radius: 50%;
                font-size: 50px;
                line-height: 160px;
                border: 2px solid #fff;
                box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
                font-weight: bold;
                margin: auto;
                color: #3f51b5;
                // @include mobile(){
                // 	margin-bottom: 110px;
                // }
                margin-bottom: 47px;
                &:after {
                    content: "";
                    @extend .d-block;
                    background-color: #3f51b5;
                    width: 5px;
                    position: absolute;
                    height: 3rem;
                    left: 0;
                    right: 0;
                    margin-top: 2px;
                    margin-left: auto;
                    margin-right: auto;
                    z-index: 1;
                }
                // @include mobile(){
                // 	&:after{

                // 		height: 7rem;
                // 	}
                // }
            }
            .description {
                padding: 35px 3px;
                position: relative;
                &:before {
                    content: "";
                    width: 15px;
                    height: 15px;
                    background-color: #3f51b5;
                    border-radius: 50%;
                    @extend .d-block;
                    margin: auto;
                    margin-top: -4.14rem;
                    position: absolute;
                    left: 0;
                    right: 0;
                    @extend .border-1;
                }
                &:after {
                    content: "";
                    @extend .d-block;
                    border-bottom: 10px solid #c5d6e0;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}

.nota-bene,
span.MuiFormLabel-asterisk {
    color: red !important;
}

.MuiDialog-paperWidthSm {
    border-radius: 1.5rem;
}

p {
    &.has__error {
        font-size: 0.75rem;
        margin: -8px 14px 12px;
    }
}
span {
    &.is__info {
        font-size: 0.75rem;
        @extend .d-block;
    }
}
.profileTab {
    max-width: 50rem;
    margin: auto;
}

.MuiDialogTitle-root {
    position: relative;
    #closeDialogue {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.reviewAddCompanyForm th .MuiBox-root {
    background-color: #3f51b5;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
}
.reviewAddCompanyForm td:first-child {
    min-width: 190px;
}
.reviewAddCompanyForm td:nth-child(2) {
    min-width: 220px;
}
.upload-input {
    position: relative;
}

.uploaded-docs {
    position: absolute;
    @extend .capitalize;
    top: 2px;
    left: 110px;
    background: #ffffff;
    padding: 5px 20px;
}
.address-card {
    min-width: 300px;
    max-width: 14.75rem;
}
.success-green {
    color: #689f38 !important;
}
.vAlign-with-text {
    vertical-align: text-top;
    @extend .success-green;
}
.vAlign-text-top {
    vertical-align: text-top;
}
.vAlignMiddle {
    vertical-align: middle;
}
.vAlignWithIcon {
    vertical-align: text-bottom;
}
.vAlignBottom {
    vertical-align: bottom;
}
.IconFontSizeXs {
    font-size: 19px;
}
.chatListDrawer {
    .MuiDrawer-paperAnchorRight {
        min-width: 18rem !important;
    }
}
// .stepperPadding {
//     .MuiStepper-root {
//         display: flex;
//         padding: 10px;
//     }
// }
.inputAdormentPosition {
    @extend .is-absolute;
    right: -19px;
    top: -12px;
}

.upperCaseVessel {
    .MuiInputBase-input {
        text-transform: uppercase;
    }
}

.quantityEdit {
    .MuiSvgIcon-fontSizeSmall {
        font-size: 16px;
        margin-bottom: -2px;
    }
}

// Add top bottom spacing
// .formSpacing {
//     padding-top: 32px;
//     padding-bottom: 32px;
// }

// Media kit

.media-kit {
    //
}

// eYard
.shape-1 {
    margin-top: -4rem;
    margin-left: -4rem;
}
.eYardBenefit-card {
    min-height: 140px;
}


@media screen and (max-width: 576px) {
    .eYardBenefit-card {
        min-height: initial;
    }
    ._404 {
        h1 {
            font-size: 5rem;
            margin-top: 1rem;

            .display-4 {
                font-size: 2rem;
            }
        }

        .error-description {
            top: 15%;
            // max-width: 30rem;
            // padding: 1.5rem;
            .error-is {
                font-size: 2rem;
                margin-bottom: 1.5rem;
                font-weight: bolder;
            }
        }

        ._404_svg-shape {
            transform: rotate(90deg);
            height: auto;
        }
    }
    .address-card {
        min-width: 220px !important;
        max-width: 14.75rem !important;
    }
    // .chatModal {
    //     font-size: 14px;
    //     // width: 300px;
    //     // bottom: 5%;
    //     right: 2px;

    //     .chat-header {
    //         padding: 0.5rem;
    //         margin-bottom: 0.4rem;
    //         cursor: pointer;
    //         td {
    //             font-size: 12px;
    //         }
    //     }

    //     .chat-body {
    //         // @extend .p-3;
    //         min-height: 20rem;
    //         max-height: 20rem;
    //         overflow-y: auto;

    //         .chat-threads {
    //             padding: 0.5rem;
    //             margin-bottom: 0.5rem;
    //             width: 14rem;
    //             font-size: 0.8rem;
    //         }
    //     }
    //     .chat-footer {
    //         margin: 0;
    //         padding: 0.5rem;
    //         background-color: #eeeeee;

    //         .input-action {
    //             .chat-input {
    //                 height: 40px;
    //             }
    //         }

    //         .form-group {
    //             margin-bottom: 0;
    //         }

    //         button {
    //             position: absolute;
    //             top: 0;
    //             right: 0;
    //             border: 0;
    //             height: 100%;
    //         }

    //         textarea {
    //             height: 40px;
    //         }
    //     }
    // }

    .partitionedInput {
        padding-left: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 245px;
        letter-spacing: 33px;
        // text-align: center;
        border: 0;
        background-image: linear-gradient(
            to left,
            black 70%,
            rgba(255, 255, 255, 0) 0%
        );
        background-position: bottom;
        background-size: 40px 1px;
        background-repeat: repeat-x;
        background-position-x: 35px;
        font-size: 0.8rem;
        outline: 0;
        margin-bottom: 20px;
    }
    .form-label__checkbox {
        display: inline-block;
        width: 100%;
    }
    .circularBoxContainer {
        margin-top: -7rem;
    }

    .productSearchContainer {
        button {
            height: 100% !important;
            display: block;
            img {
                width: 30px;
                margin-right: 5px;
            }
        }


        .searchButtonGroup {
            height: 100%;
            width: 100%;
            button {
                border-radius: 0px;
                .MuiButton-label {
                    font-size: 12px !important;
                }
            }
        }

        .MuiButtonGroup-contained,
        .MuiButton-contained:hover {
            box-shadow: none;
        }

        select,
        .MuiOutlinedInput-root {
            border-radius: 0 !important;
        }
    }

    .shape-1 {
        margin-top: -1rem;
        margin-left: -1rem;
    }
    h2.shape-1_title {
        margin-top: 8rem;
    }
    .shape-1_title,
    .shape-2_title {
        font-size: 40px !important;
    }
    .shape-2_title {
        right: 0;
        position: absolute;
        top: 103px;
    }
    .title-container {
        position: relative;
        z-index: 99;
        .MuiTypography-h4 {
            font-size: 1.5rem;
        }
    }
}
.title-container {
    position: relative;
    z-index: 99;
    // .MuiTypography-h4 {
    //     @extend .text-white;
    // }
}

.title-svg {
    z-index: -1;
    top: -18px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    @extend .d-block;
}

.graphic-box {
    @extend .text-center;
    max-width: 300px;
    padding: 10px 0;
    min-height: 220px;
    margin: auto;
    // position: relative;
    .card-footer {
        padding: 5px 0;
        bottom: 0;
        width: 100%;
        @extend .bgGrad;
    }
    // img {
    //     height: 120px;
    // }
}

.partner-logo-link {
    // min-height: 280px;
    // overflow: hidden;
    // border: 1px solid #ddd;
}

.inner-links {
    .MuiCard-root {
        @extend .bgGrad;
    }
}
.linkedin {
    background: #1a83d4;
}
.twitter {
    background: #14b3ea;
}
.fb {
    background: #294072;
}
.video-card {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    @extend .w-100;
    iframe {
        @extend .w-100;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
.media-partners {
    .partner-logo-link {
        padding: 2rem;
        min-height: 280px;
        overflow: hidden;
        border: 5px solid #f7f5ff;
        .MuiTypography-h5 {
            bottom: 0;
            right: 0;
            left: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            @extend .bgGrad;
            font-size: 1.2rem;
            padding: 0.4rem;
        }
    }
}
.media-resources {
    .media-resources-card.web {
        // background-image: url('/public/media/front/coalshastra-website-ui.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        transition: 5s;
    }
    .media-resources-card.mobile {
        // background-image: url('/media/front/Coalshastra-app-ui.png');
        // background-image: url('/public/media/front/Splash-screen–2.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        transition: 5s;
    }
    .media-resources-card {
        max-width: 55rem;
        min-height: 30rem;
        max-height: 30rem;
        overflow-y: hidden;
        @extend .border-1;
        &:hover {
            background-position: 0 100%;
        }
    }
}
.company-facts {
    .card-content {
        @extend .border-1;
    }
}
.about-hero {
    max-height: 600px;
    overflow: hidden;
}
.rounded8TlTr {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.rounded8BlBr {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.stackedShadow {
    @extend .is-relative;
    &:after,
    &:before {
        content: "";
        display: flex;
        height: 100%;
        width: 100%;
        @extend .is-absolute;
    }
    &:after {
        box-shadow:
            // 0 1px 1px rgba(0, 0, 0, 0.15),
            // 0 10px 0 -5px #eee, 0 11px 1px -4px
            rgb(236, 226, 255);
        bottom: -15px;
        bottom: 0px;
        @extend .rounded8BlBr;
    }
    // &:before {
    //     box-shadow:
    //         // 0 1px 1px rgba(0, 0, 0, 0.15),
    //         // 0 10px 0 -5px #eee,
    //         0 11px 1px -4px rgb(226, 256, 255);
    //     bottom: -30px;
    //     bottom: 0px;
    //     @extend .rounded8BlBr;
    // }
}
.linkColor {
    color: #0b4e6e !important;
}

// Animated success UI
.success-checkmark {
    width: 80px;
    // height: 115px;
    margin: 0 auto;

    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4caf50;

        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }

        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }

        &::before,
        &::after {
            content: "";
            height: 100px;
            position: absolute;
            background: #ffffff;
            transform: rotate(-45deg);
        }

        .icon-line {
            height: 5px;
            background-color: #4caf50;
            @extend .d-block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;

            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }

            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }

        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, 0.5);
        }

        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #ffffff;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.blinking {
    animation: 1s blink ease infinite;
    border-radius: 4px;
    display: inline-block;
    padding: 2px 6px 4px;
    @extend .text-white;
  }
.blinkAnimation {
    animation: 1s blinkAnimation ease infinite;
  }


.new-feature {
    animation: wiggle 2.25s ease-in;
}
@keyframes wiggle {
    0% {-webkit-transform: rotate(10deg);}
  25% {-webkit-transform: rotate(-15deg);}
  50% {-webkit-transform: rotate(25deg);}
  75% {-webkit-transform: rotate(-15deg);}
  100% {-webkit-transform: rotate(0deg);}
}

// Animated error UI

.ui-error {
    width: 100px;
    height: 100px;
    margin: 40px;
    &-circle {
        stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
        stroke-dashoffset: 260.75219024795285px;
        animation: ani-error-circle 1.2s linear;
    }
    &-line1 {
        stroke-dasharray: 54px 55px;
        stroke-dashoffset: 55px;
        stroke-linecap: round;
        animation: ani-error-line 0.15s 1.2s linear both;
    }
    &-line2 {
        stroke-dasharray: 54px 55px;
        stroke-dashoffset: 55px;
        stroke-linecap: round;
        animation: ani-error-line 0.2s 0.9s linear both;
    }
}

@keyframes ani-error-line {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes ani-error-circle {
    0% {
        stroke-dasharray: 0, 260.75219024795285px;
        stroke-dashoffset: 0;
    }
    35% {
        stroke-dasharray: 120px, 120px;
        stroke-dashoffset: -120px;
    }
    70% {
        stroke-dasharray: 0, 260.75219024795285px;
        stroke-dashoffset: -260.75219024795285px;
    }
    100% {
        stroke-dasharray: 260.75219024795285px, 0;
        stroke-dashoffset: -260.75219024795285px;
    }
}

.stepContentBorder {
    .MuiStepContent-root {
        margin-top: 0;
        border-left: none;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        border-left: none;
    }
    .MuiStepConnector-vertical {
        padding: 0 !important;
    }
}
.remark {
    width: 450px;
    textarea, MuiInputLabel-root, span, legend, label {
        @extend .text-white
    }
    .MuiOutlinedInput-multiline {
        background: #213086;
    }

}
// .rating-stepper-content {

// }
.MuiStepperRoot {
    .MuiStepConnector-lineVertical {
        min-height: 0;
        border-left-style: none;
        border-left-width: 0;
    }
}
.d-inline-block {display: inline-block !important;}
.float-right {
    float: right
}

.loader-button-wrapper {
    position: relative;
    display: inline-block;
    .button-loader {
        position: absolute
    }
}


.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    // background: grey;
    margin-bottom: 15px;
  }
.chat-body {
    // background-color: rgb(255 255 255);
    background-image: url('../../public/images/logo-watermark1.png');
    background-repeat: no-repeat;
    background-position: center;
    // min-height: 28rem;
    min-height: 20rem;
    max-height: 420px;
    // max-height: 20rem;
    overflow-y: auto;

    .chat-threads {
        padding: 0.6rem 1rem;
        margin-bottom: 1rem;
        width: 19rem;
    }

    .chat-thread-initiated {
        text-align: left;
        @extend .chat-threads;
        //background-color: #FF0000;
        //background: linear-gradient(0deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 0, 0.2)), #FFFFFF;
        float: left;
        margin-left: 12px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        color: #4e5152;
        // box-shadow: 5px 6px 13px #ddd;
        border: 1px solid transparent;
        // margin-left: calc(460px - 120rem)
    }

    .chat-thread-response {
        // text-align: right;
        @extend .chat-threads;
        margin-bottom: 10px;
        margin-right: 12px;
        color: #4e5152;
        //   @extend .text-white;
        float: right;
        // background: linear-gradient(0deg, rgba(78, 159, 61, 0.3), rgba(78, 159, 61, 0.3)), #FFFFFF;
       // background-color: #4E9F3D;
        //   background-color: #2795f3;
        //   background-image: linear-gradient(
        //     315deg,
        //     #643db9,
        //     #4764d3 57%,
        //     #3181e5 86%
        //   );
        // background: #3f51b5;
        // box-shadow: 5px 6px 13px #b4b7bfad;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .chat-thread-system {
        //text-align: left;
        @extend .chat-threads;
        //background-color: #FF0000;
        background: linear-gradient(0deg, #E0E0E0, #E0E0E0), #FFFFFF;
        float: right;
        margin-left: 12px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        color: #4e5152;
        // box-shadow: 5px 6px 13px #ddd;
        border: 1px solid transparent;
        // margin-left: calc(460px - 120rem)
    }
}

.chat-footer {
    margin: 0;
    padding: 0.5rem;
    background-color: #eeeeee;

    .input-action {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: 80% auto auto;
        align-items: center;
        position: relative;

        .chat-input {
            height: 45px;
        }
    }

    .form-group {
        margin-bottom: 0;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        background: #3f51b5;
        border: 0;
        height: 100%;
    }

    textarea {
        height: 60px;
        font-size: 14px;
        border: 0;
        outline-style: solid;
        outline-color: #cfdbff;
    }
}

.countdown-span {
    background: #2959ba;
    width: 30px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFF;
    margin: 0px 4px
}

.silverUnlimitedAtom {
    position:relative;
    background: rgb(253,254,255);
    background: linear-gradient(124deg, rgba(253,254,255,1) 0%, rgba(219,219,219,1) 25%, rgba(255,255,255,1) 49%, rgba(209,209,209,1) 73%, rgba(252,254,255,1) 100%);

    &:after {
        content:'';
        top:0;
        transform:translateX(100%) rotate(30deg);
        width:300%;
        height:300%;
        position: absolute;
        z-index:1;
        animation: shine 3s infinite ease-in;
        background: linear-gradient( to right,
            rgba(255,255,255,0) 0%,
            rgba(255,255,255,0.8) 50%,
            rgba(128,186,232,0) 99%,
            rgba(128,186,232,0) 100%);
    }
}